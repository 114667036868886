import React from 'react'
import Layout from '../components/Layout'
import CallBackBanner from '../components/CallBackBanner'
import PromotionBanner from '../components/PromotionBanner/PromotionBanner'
import GeneralEnquiryForm from '../components/shared/Form/GeneralEnquiryForm'
import img from '../images/industry-sector/team_industry.svg'
import IndustryTemplateLayout from '../components/IndustrySector/IndustryTemplateLayout'
import MainGrid from '../components/styling/MainGrid'
import Breadcrumb from '../components/shared/Breadcrumb/Breadcrumb'
import IndustryLeftCol from '../components/IndustrySector/IndustryLeftCol'
import IndustryRightCol from '../components/IndustrySector/IndustryRightCol'
import IndustryDescription from '../components/IndustrySector/IndustryDescription'
import logo from '../images/homepage/popin-anim/retailer.svg'
import CaseStudyContent from '../components/CaseStudy/CaseStudyContent'

const breadcrumbData = [
  {
    title: 'Home',
    url: '/',
  },
  {
    title: 'About us',
    url: '/about-us',
  },
  {
    title: 'Case Studies',
    url: '/case-studies',
  },
  {
    title: 'Beaumont Seymour',
    url: '/hilton-food',
  },
]

const HiltonFood = () => {
  return (
    <Layout
      activeLink="/case-studies"
      title="R&D Hilton Food Group"
      description="R&D Hilton Food Group"
    >
      <MainGrid noPaddingMobile noPaddingTablet>
        <Breadcrumb data={breadcrumbData} />
      </MainGrid>
      <IndustryTemplateLayout>
        <IndustryLeftCol
          category="Case Studies"
          title="Hilton Food Group"
          backLink="/case-studies"
        />
        <IndustryRightCol>
          <IndustryDescription title="Hilton Food Group" />
          <CaseStudyContent
            heading="Global Food Supplier"
            paragraph='"I had a limited understanding of RDA tax relief before talking to them and I had received a few emails, but I was unsure that it applied to our business.  We initially began to work with RDA and understand them, and we have been pleased with the process and the outcome, achieving great results each time.'
            // imgDesktop={img}
            // imgMobile={img}
            alt="Aeroplane"
            logo={logo}
            content={[
              <div>
                <p>
                  The level of support has always been fantastic, it had initially seemed as if it might be an intimidating and time-consuming administrative minefield, but RDA really made things simple for us. My business colleagues are now continuing to work with RDA, which means I can get on with running the business and they in turn have been very happy with the results.
                </p>
              </div>,
            ]}
          />
        </IndustryRightCol>
      </IndustryTemplateLayout>
      <PromotionBanner />
      <GeneralEnquiryForm />
      <CallBackBanner />
    </Layout>
  )
}

export default HiltonFood
